import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} className="paper-container">
                        <div className="contact-details">
                            <h2>ติดต่อสั่งซื้อหรือสอบถามรายละเอียดเพิ่มเติม</h2>
                            <h2>หจก.เจ.เอส เพาว์เวอร์ อีเลคทริค</h2>
                            <div className="icon-contact">
                                <i className="fa-solid fa-location-dot"></i>
                                <span>ที่อยู่</span>
                            </div>
                            <div className="text-contact">
                                <p>
                                    ห้างหุ้นส่วนจำกัด เจ.เอส เพาว์เวอร์ อีเลคทริค<br />
                                    ที่ตั้ง 53/24 หมู่ที่ 2 ตำบลไร่ขิง อำเภอสามพราน<br />
                                    จังหวัดนครปฐม 73210
                                </p>
                            </div>
                            <div className="icon-contact">
                                <i className="fa-solid fa-phone"></i>
                                <span>เบอร์โทร</span>
                            </div>
                            <div className="text-contact">
                                <p>คุณ เจริญ(เจ้าของบริษัท) โทร: 089-2021733 , 034-318334 , 0861606142<br />
                                    แฟกซ์ โทร: 034-318335
                                </p>
                            </div>
                            <div className="icon-contact">
                                <i className="fa-solid fa-envelope"></i>
                                <span>อีเมล</span>
                            </div>
                            <div className="text-contact">
                                <p>jspower.etc@gmail.com</p>
                            </div>
                            <div className="icon-contact">
                                <i className="fa-solid fa-clock"></i>
                                <span>เวลาเปิดทำการ</span>
                            </div>
                            <div className="text-contact">
                                <p>เปิดทำการทุกวัน 8:30 - 18:30 น.</p>
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} className="paper-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7751.341827588364!2d100.259452!3d13.738363!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2eb066732af43%3A0xcb8579f241214411!2z4Lir4LiI4LiBLiDguYDguIgu4LmA4Lit4LiqIOC5gOC4nuC4suC4p-C5jOC5gOC4p-C4reC4o-C5jCDguK3guLXguYDguKXguITguJfguKPguLTguIQ!5e0!3m2!1sth!2sth!4v1708272288419!5m2!1sth!2sth"
                            width="100%"
                            height="400"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>

                        <div className="lineimage">
                            <div className="lineimage1">
                                <img src='/image/head/line.jpg' alt='' />
                                <p>ติดต่อ คุณเจริญ</p>
                            </div>
                            <div className="lineimage2">
                                <img src='/image/head/line1.jpg' alt='' />
                                <p>ติดต่อ คุณฐานิต</p>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default Contact;
