import React from 'react';
import NewProduct from './pages/NewProduct'
import BestSeller from './pages/BastSeller'
import Home from './Home'


const Homepage = () => {
    return (
        <>
            <Home />
            <p style={{ fontSize: '40px', textAlign: 'center', marginTop: '150px', marginBottom: '-150px' }}>สินค้าขายดี</p>
            <BestSeller />
            <p style={{ fontSize: '40px', textAlign: 'center', marginTop: '50px', marginBottom: '-150px' }}>สินค้ามาใหม่</p>
            <NewProduct />
            <p style={{ marginBottom: '100px' }}></p>
        </>
    )
}

export default Homepage