import React from 'react';
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

function NewProduct() {
    // รายการรูปภาพ
    const images = [
        { original: "/image/product/p16.jpg", width: 1200, height: 900 },
        { original: "/image/product/p22.jpg", width: 1200, height: 900 },
        { original: "/image/product/p27.jpg", width: 1200, height: 900 },
        { original: "/image/product/p25.jpg", width: 1200, height: 900 },
        { original: "/image/product/p12.jpg", width: 1200, height: 900 },
        { original: "/image/product/p14.jpg", width: 1200, height: 900 },
        { original: "/image/product/p15.jpg", width: 1200, height: 900 },
        { original: "/image/product/p13.jpg", width: 1200, height: 900 },
    ];

    return (
        <div>
            <section className="services" id="NewProduct">
                <Gallery>
                    <ul className="cards">
                        {images.map((image, index) => (
                            <li className="card" key={index}>
                                <Item {...image}>
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} src={image.original} alt='' />
                                    )}
                                </Item>
                            </li>
                        ))}
                    </ul>
                </Gallery>
            </section>
        </div>
    )
}

export default NewProduct;