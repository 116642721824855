import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import Product from "./components/Product";
import Review from "./components/Review";
import Reviewproductpage from "./components/pages/Reviewproductpage";
import Reviewservicepage from "./components/pages/Reviewservicepage";
import Contact from "./components/Contact";
import Aboutus from "./components/Aboutus";
import Footer from "./components/Footer";
import GoTop from "./components/GoTop";
import "./App.css";

function App() {
  return (
    <Router>
      <GoTop />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<><Header /><Homepage /></>} />
        <Route path="/product" element={<><Header /><Product /></>} />
        <Route path="/review" element={<><Header /><Review /></>} />
        <Route path="/aboutus" element={<><Header /><Aboutus /></>} />
        <Route path="/contact" element={<><Header /><Contact /></>} />
        <Route path="/review/การทำงาน" element={<><Header /><Reviewproductpage /></>} />
        <Route path="/review/การให้บริการ" element={<><Header /><Reviewservicepage /></>} />
      </Routes>
      <div className="Footercontact">
        <Footer />
      </div>
    </Router>
  );
}

export default App;
