import React from 'react';
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import './Product.css';

function Productpage() {
    // รายการรูปภาพ
    const images = [
        { original: "/image/product/p1.png", width: 1200, height: 900 },
        { original: "/image/product/p2.png", width: 1200, height: 900 },
        { original: "/image/product/p3.png", width: 1200, height: 900 },
        { original: "/image/product/p4.png", width: 1200, height: 900 },
        { original: "/image/product/p5.png", width: 1200, height: 900 },
        { original: "/image/product/p6.png", width: 1200, height: 900 },
        { original: "/image/product/p7.jpg", width: 1200, height: 900 },
        { original: "/image/product/p8.jpg", width: 1200, height: 900 },
        { original: "/image/product/p9.jpg", width: 1200, height: 900 },
        { original: "/image/product/p10.png", width: 1200, height: 900 },
        { original: "/image/product/p11.jpg", width: 1200, height: 900 },
        { original: "/image/product/p12.jpg", width: 1200, height: 900 },
        { original: "/image/product/p13.jpg", width: 1200, height: 900 },
        { original: "/image/product/p14.jpg", width: 1200, height: 900 },
        { original: "/image/product/p15.jpg", width: 1200, height: 900 },
        { original: "/image/product/p16.jpg", width: 1200, height: 900 },
        { original: "/image/product/p17.jpg", width: 1200, height: 900 },
        { original: "/image/product/p18.jpg", width: 1200, height: 900 },
        { original: "/image/product/p19.jpg", width: 1200, height: 900 },
        { original: "/image/product/p20.jpg", width: 1200, height: 900 },
        { original: "/image/product/p21.jpg", width: 1200, height: 900 },
        { original: "/image/product/p22.jpg", width: 1200, height: 900 },
        { original: "/image/product/p23.jpg", width: 1200, height: 900 },
        { original: "/image/product/p24.jpg", width: 1200, height: 900 },
        { original: "/image/product/p25.jpg", width: 1200, height: 900 },
        { original: "/image/product/p26.jpg", width: 1200, height: 900 },
        { original: "/image/product/p27.jpg", width: 1200, height: 900 },
        { original: "/image/product/p28.jpg", width: 1200, height: 900 },
    ];

    return (
        <div>
            <section className="services" id="productpage">
                <h2>ผลิตภัณฑ์</h2>
                <Gallery>
                    <ul className="cards">
                        {images.map((image, index) => (
                            <li className="card" key={index}>
                                <Item {...image}>
                                    {({ ref, open }) => (
                                        <img ref={ref} onClick={open} src={image.original} alt='' />
                                    )}
                                </Item>
                            </li>
                        ))}
                    </ul>
                </Gallery>
            </section>
        </div>
    )
}

export default Productpage;

