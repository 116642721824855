import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <div>
            <header>
                <nav className="navbar">
                    <i className="logo">
                        <Link to="/">
                            <img src="/image/head/logo.png" alt="Logo" />
                        </Link>
                    </i>
                    <nav role="navigation">
                        <div id="menuToggle">
                            <input type="checkbox" />
                            <span></span>
                            <span></span>
                            <span></span>
                            <ul id="menu">
                                <li>
                                    <Link to="/product">
                                        <i className="fa-solid fa-screwdriver-wrench" style={{ color: "#ffffff" }}></i>{" "}
                                        ผลิตภัณฑ์
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/review">
                                        <i className="fa-solid fa-screwdriver-wrench" style={{ color: "#ffffff" }}></i>{" "}
                                        ผลงานการทำงาน
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/aboutus">
                                        <i className="fa-solid fa-book" style={{ color: "#ffffff" }}></i>{" "}
                                        เกี่ยวกับเรา
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        <i className="fa-solid fa-envelope" style={{ color: "#ffffff" }}></i>{" "}
                                        ติดต่อเรา
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <ul className="all-links">
                        <li>
                            <Link to="/product">
                                <i className="fa-solid fa-screwdriver-wrench" style={{ color: "#ffffff" }}></i>{" "}
                                ผลิตภัณฑ์
                            </Link>
                        </li>
                        <li>
                            <Link to="/review">
                                <i className="fa-solid fa-screwdriver-wrench" style={{ color: "#ffffff" }}></i>{" "}
                                ผลงานการทำงาน
                            </Link>
                        </li>
                        <li>
                            <Link to="/aboutus">
                                <i className="fa-solid fa-book" style={{ color: "#ffffff" }}></i>{" "}
                                เกี่ยวกับเรา
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact">
                                <i className="fa-solid fa-envelope" style={{ color: "#ffffff" }}></i>{" "}
                                ติดต่อเรา
                            </Link>
                        </li>
                    </ul>
                </nav>
            </header>
        </div>
    )
}
